import React, { useEffect, useRef, useState } from "react";
import "../Footer/Footer.css";
import star from "../../images/star.png";
import call from "../../images/call.png";
import mail from "../../images/mail.png";
import webworld from "../../images/webworld.png";
import location from "../../images/location.png";
import wp from "../../images/wp.png";
import fb from "../../images/fb.png";
import insta from "../../images/insta.png";
import linkdin from "../../images/linkdin.png";
import mobileback from "../../images/mobileback.png";
import emailback from "../../images/emailback.png";
import websitback from "../../images/websiteback.png";
import locationback from "../../images/locationback.png";
import normalline from "../../images/normalline.png";
import { Link } from "react-router-dom";
import CursorDot from "../CursorDot";



const Footer = () => {
  
  const [items, setItems] = useState([
    "Graphic Designing",
    "Digital Marketing",
    "Web Development",
    "Film Production",
    "Video Advertisement",
    "CGI Videos",
  ]);
  
  const [isDragging, setIsDragging] = useState(false);
  const marqueeTrackRef = useRef(null);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onMouseDown = (event) => {
    setIsDragging(true); // Pause marquee animation
    setStartX(event.pageX - marqueeTrackRef.current.offsetLeft);
    setScrollLeft(marqueeTrackRef.current.scrollLeft);
  };

  const onMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - marqueeTrackRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Sensitivity factor
    marqueeTrackRef.current.scrollLeft = scrollLeft - walk;
  };

  const onMouseUp = () => {
    setIsDragging(false); // Resume marquee animation
  };

  const onMouseLeave = () => {
    setIsDragging(false); // Resume marquee animation
  };
// njnnjn

  
  return (
    <>
      <section className="seco" >
      <article className={`main-container__marquees ${isDragging ? "marquee-paused" : ""}`}>
          <div
            className="main-container__marquee-track"
            ref={marqueeTrackRef}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
          >
            <div className="main-container__marquee-items">
              {items.map((item, index) => (
                <span key={index} className="main-container__marquee-item">
                  {item}
                  <img src={star} alt="" className="main-content" />
                </span>
              ))}
            </div>
            <div aria-hidden="true" className="main-container__marquee-items">
              {items.map((item, index) => (
                <span key={index + items.length} className="main-container__marquee-item">
                  {item}
                  <img src={star} alt="" className="main-content" />
                </span>
              ))}
            </div>
          </div>
        </article>
        <CursorDot />
        
        {/* <article className="main-container__marquees">
          <div className="main-container__marquee-track">
            <div className="main-container__marquee-items">
              <span className="main-container__marquee-item">
                Graphic Designing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Digital Marketing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Web Development
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Film Production
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Video Advertisement
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">CGI Videos</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
            </div>
            <div aria-hidden="true" className="main-container__marquee-items">
              <span className="main-container__marquee-item">
                Graphic Designing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Digital Marketing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Web Development
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Film Production
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Video Advertisement
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">CGI Videos</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
            </div>
          </div>
        </article> */}

        <div className="footer-bg">
          <div style={{ paddingTop: "50px" }}>
            <div className="main-width">
              <div className="all-padding">
                <div className="width1320px">
                  <div className="d-grid">
                    <div className="width650">
                    <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="tel:94996 19901"
                  >
                      <div className="contact-display">
                        <img src={mobileback} alt="" />
                        <div className="contact-text">
                          <p className="contact-name">Mobile</p>
                          <p className="contact-number">(+91) 94996 19901</p>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="width650">
                    <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="mailto:info@teamflourish.co"
                  >
                      <div className="contact-display">
                        <img src={emailback} alt="" />
                        <div className="contact-text">
                          <p className="contact-name">Email</p>
                          <p className="contact-number">info@teamflourish.co</p>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="width650" >
                      <Link
                      className="noone"
                        to="https://teamflourish.co/"
                        target="_blan"
                        rel="nopper"
                        
                      >
                        <div className="contact-display">
                          <img src={websitback} alt="" />
                          <div className="contact-text">
                            <p className="contact-name">Website</p>
                            <p className="contact-number">
                              www.teamflourish.co
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="width650">
                    <Link
                    to="https://www.google.com/maps/dir//408,+Tapas+Elegance,+nr.+Nehru+Nagar+Circle,+H+Colony,+Ambawadi,+Ahmedabad,+Gujarat+380015/@23.0238924,72.4619048,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0xa43da99557fa49cf:0xa31f06eacb32b24c!2m2!1d72.5443064!2d23.0239137?entry=ttu"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="contact-display">
                        <img src={locationback} alt="" />
                        <div className="contact-text">
                          <p className="contact-name">Location</p>
                          <p className="contact-number">Ahmedabad</p>
                        </div>
                      </div>
                      </Link>
                    </div>
                    
                  </div>
                  <div className="social-icon">
                    <img className="normalline" src={normalline} alt="" />
                    <div className="social-flex">
                    <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="tel:94996 19901"
                  >
                      <div className="socialcircle">
                        <img src={call} alt="" />
                      </div>
                      </Link>
                      <Link
                    style={{ color: "white", textDecoration: "none" }}
                    to="mailto:info@teamflourish.co"
                  >
                      <div className="socialcircle">
                        <img src={mail} alt="" />
                      </div>
                      </Link>
                      <Link
                    to="https://teamflourish.co/"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="socialcircle">
                        <img src={webworld} alt="" />
                      </div>
                      </Link>
                      <Link
                    to="https://www.google.com/maps/dir//408,+Tapas+Elegance,+nr.+Nehru+Nagar+Circle,+H+Colony,+Ambawadi,+Ahmedabad,+Gujarat+380015/@23.0238924,72.4619048,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0xa43da99557fa49cf:0xa31f06eacb32b24c!2m2!1d72.5443064!2d23.0239137?entry=ttu"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="socialcircle">
                        <img src={location} alt="" />
                      </div>
                      </Link>
                      <Link
                    to={"https://wa.me/9499619901"}
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="socialcircle">
                        <img src={wp} alt="" />
                      </div>
                      </Link>
                      <Link
                    to="https://www.facebook.com/people/Flourish-Creations-Pvt-Ltd/100084758949547/"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="socialcircle">
                        <img src={fb} alt="" />
                      </div>
                      </Link>
                      <Link
                    to="https://www.instagram.com/team.flourish_/"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="socialcircle">
                        <img src={insta} alt="" />
                      </div>
                      </Link>
                      <Link
                    to="https://in.linkedin.com/company/flourish-creations-pvt-ltd"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="socialcircle">
                        <img src={linkdin} alt="" />
                      </div>
                      </Link>
                    </div>
                    <img className="normalline" src={normalline} alt="" />
                  </div>
                  <Link
                  className="noone"
                    to="https://teamflourish.co/"
                    target="_blan"
                    rel="nopper"
                  >
                  <div className="footer">
                  
                    <p>Copyrights © 2024 Flourish All rights reserved.</p>
                    
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
