import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import CursorDot from './Components/CursorDot';
import Footer from './Components/Footer/Footer';
import Digitalmarketing from './Components/DigitalMarketing/Digitalmarketing';
import Graphichdesign from './Components/GraphicDesigning/Graphichdesign';

function App() {
  return (
    <div className="App">
      <CursorDot/>
      <Navbar/>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/Graphichdesign" element={<Graphichdesign />} />
      <Route path="/Digitalmarketing" element={<Digitalmarketing />} />
      </Routes>
      
      
      <Footer/>
    </div>
  );
}

export default App;
