import React, { useEffect, useRef, useState } from "react";
import "../Navbar/Navbar.css";
import star from "../../images/star.png";
import flourihlogo from "../../images/flourishlogo.png";
import { Link } from "react-router-dom";
import CursorDot from "../CursorDot";

const Navbar = () => {
 

// jihugvhjighjiokijhg
const [items, setItems] = useState([
  "Graphic Designing",
  "Digital Marketing",
  "Web Development",
  "Film Production",
  "Video Advertisement",
  "CGI Videos",
]);

const [isDragging, setIsDragging] = useState(false);
const marqueeTrackRef = useRef(null);
const [startX, setStartX] = useState(0);
const [scrollLeft, setScrollLeft] = useState(0);

const onMouseDown = (event) => {
  setIsDragging(true); // Pause marquee animation
  setStartX(event.pageX - marqueeTrackRef.current.offsetLeft);
  setScrollLeft(marqueeTrackRef.current.scrollLeft);
};

const onMouseMove = (event) => {
  if (!isDragging) return;
  const x = event.pageX - marqueeTrackRef.current.offsetLeft;
  const walk = (x - startX) * 2; // Sensitivity factor
  marqueeTrackRef.current.scrollLeft = scrollLeft - walk;
};

const onMouseUp = () => {
  setIsDragging(false); // Resume marquee animation
};

const onMouseLeave = () => {
  setIsDragging(false); // Resume marquee animation
};
// kjnbhv bjkoijbhvbjikopkijh
  return (
    <>
      <section>
        <div className="skewed-bg">
          <div className="main-width">
            <div className="all-padding">
              <div className="width1320">
              <Link
                      className="noone"
                        to="https://teamflourish.co/"
                        target="_blan"
                        rel="nopper"
                        
                      >
                <div className="flourish-logo">
                  <img src={flourihlogo} alt="" />
                </div>
                </Link>
                <div className="width1185">
                  <div className="banner-container">
                    <p className="banner-heading">
                      {" "}
                      <span className="underlined">Innovative </span> Designs, Unmatched Impact. 
                    </p>
                    <p className="banner-pera">
                      Step into a world where design meets innovation. Our bespoke solutions not only enhance your brand's visual appeal but also drive engagement and growth. Let us turn your ideas into stunning realities and set your brand apart in the digital landscape.
                    </p>
                    <div className="dots-wrapper"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <article className={`main-container__marquee ${isDragging ? "marquee-paused" : ""}`}>
          <div
            className="main-container__marquee-tracks"
            ref={marqueeTrackRef}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
          >
            <div className="main-container__marquee-itemss">
              {items.map((item, index) => (
                <span key={index} className="main-container__marquee-item " >
                  {item}
                  <img src={star} alt="" className="main-content" />
                </span>
              ))}
            </div>
            <div aria-hidden="true" className="main-container__marquee-itemss">
              {items.map((item, index) => (
                <span key={index + items.length} className="main-container__marquee-item">
                  {item}
                  <img src={star} alt="" className="main-content" />
                </span>
              ))}
            </div>
          </div>
        </article>
        <CursorDot />
        {/* <article className="main-container__marquee">
          <div className="main-container__marquee-tracks">
            <div className="main-container__marquee-itemss">
              <span className="main-container__marquee-item">
                Graphic Designing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Digital Marketing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">Web Development</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">Film Production</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Video Advertisement
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">CGI Videos</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
            </div>
            <div aria-hidden="true" className="main-container__marquee-itemss">
              <span className="main-container__marquee-item">
                Graphic Designing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Digital Marketing
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">Web Development</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">Film Production</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">
                Video Advertisement
              </span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
              <span className="main-container__marquee-item">CGI Videos</span>
              <span className="main-container__marquee-item main-content">
                {" "}
                <img src={star} alt="" />{" "}
              </span>
            </div>
          </div>
        </article> */}
      </section>
    </>
  );
};

export default Navbar;
