import React from 'react'
import logodesigning from "../../images/logodesigning.png";
import packagedesign from "../../images/packagedesign.png";
import stationarydesign from "../../images/stationarydesign.png";
import socialmediapost from "../../images/socialmediapost.png";
import flyrdesign from "../../images/flyrdesign.png";
import brochuredesign from "../../images/brochuredesign.png";
import festivaldeign from "../../images/festivaldeign.png";
import cursorimage from "../../images/cursorimage.png";
import { Link } from 'react-router-dom';

const Graphichdesign = () => {
  return (
    <section>
        <div>
          <div className="main-width">
            <div className="all-padding">
              <div className="width1320-width1320">
                <p className="card-heading">Graphic Designing</p>

                <div className="width-width-temporary">
                  <div className="grid3">
                    
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                    to="https://drive.google.com/drive/folders/1pbzMTGLGMe4KIscGujc8jyNwBxV06rVc"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={logodesigning}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Logo </span> <span> Designing </span>
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                    to="https://drive.google.com/drive/folders/1HUTkx9qd4XJ5emPO0Wde-DTZ1twzGur0"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={packagedesign}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Packaging </span> <span> Design </span>
                              
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                    to="https://drive.google.com/drive/folders/1PWg1kchbGDFbmlqrvGQn5iVhoPBc_h6e"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={stationarydesign}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Stationary </span> <span> Design </span>{" "}
                               
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                    to="https://drive.google.com/drive/folders/1fECw5XQV8dcMsv6V8uSR4N9GpyNpwoa9"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={socialmediapost}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Social </span> <span> Media Post </span>
                                
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                    to="https://drive.google.com/drive/folders/16CBlCFeRXSRVE-OUlWEVw__x2AlisYzN"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={flyrdesign}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Flyer </span> <span>Design </span>{" "}
                                
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                    to="https://drive.google.com/drive/folders/1g2g_343BlpofsFp8nzbwU79EnAdQ6l7L"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={brochuredesign}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Brochure </span> <span>Design </span>{" "}
                                
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                    to="https://drive.google.com/drive/folders/1Ji9cxORHEBmSmHEE7B42EZdKWs1va62A"
                    target="_blan"
                    rel="nopper"
                  >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={festivaldeign}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Festival </span> <span>Post Design </span>{" "}
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Graphichdesign