import React from "react";
import "../Home/Home.css";
import graphic from "../../images/graphic.png";
import cursorimage from "../../images/cursorimage.png";
import digitalmarketing from "../../images/digitalmarketing.png";
import webdev from "../../images/webdev.png";
import filmp from "../../images/filmp.png";
import videoa from "../../images/videoa.png";
import gci from "../../images/gci.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section>
        <div>
          <div className="main-width">
            <div className="all-padding">
              <div className="width1320-width1320">
                <p className="card-heading">Our Recent Work</p>

                <div className="width-width-temporary">
                  <div className="grid3">
                    
                    <div className="main-main-mian-box">
                    <Link className="noone" to="/Graphichdesign">
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={graphic}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Graphic </span> <span> Designing </span>
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    
                    <div className="main-main-mian-box">
                    <Link className="noone" to="/Digitalmarketing">
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={digitalmarketing}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Digital </span> <span> Marketing </span>
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/1W7WPH_jcBnAtPLBfaFa_0lYMIsAjaVK3"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={webdev}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Web </span> <span> Development </span>{" "}
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/1dd3hR0xHgtEnAgMuk4UuugPLP_Uykw-5"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={filmp}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Film </span> <span> Production </span>
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/1GTQgMtcBUPA0aCnUyAp93RD21pKQRV9N"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={videoa}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Video </span> <span>Advertisement </span>{" "}
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/16sNl10CoJkEhY55k9nja3kwgD7hPHQeS"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={gci}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> CGI </span> <span>Videos </span>{" "}
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
