import React from 'react'
import googleads from "../../images/googleads.png";
import facebookads from "../../images/facebookads.png";
import contentwriting from "../../images/contentwriting.png";
import socialmanagement from "../../images/socialmanagement.png";
import cursorimage from "../../images/cursorimage.png";
import { Link } from 'react-router-dom';

const Digitalmarketing = () => {
  return (
    <section>
        <div>
          <div className="main-width">
            <div className="all-padding">
              <div className="width1320-width1320">
                <p className="card-heading">Digital Marketing</p>

                <div className="width-width-temporary">
                  <div className="grid3">
                    
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/1AYXuhMI1Y7Jpt1x-_HcDMI_NqLx8K646"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={googleads}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Google </span> <span> Ads </span>
                               
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/1hq7yU0YMREX8w68eJa8b0aUi7EtKI5Sg"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={facebookads}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Facebook </span> <span> Ads </span>
                               
                              
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/11sre0OLKxclVroseHZjhOHgS8H82ExnV"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={contentwriting}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Content </span> <span> Writing </span>{" "}
                                
                               
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    <div className="main-main-mian-box">
                    <Link
                    className="noone"
                to="https://drive.google.com/drive/folders/1_KQ_u3RDPrVKqDqgIxmquPqkA-n92st0"
                target="_blan"
                rel="nopper"
              >
                      <div className="card-dot-border">
                        <div className="card-slid-border">
                          <div class="circle-doted-animation">
                            <div class="border-wrapper">
                              <div class="border-animation"></div>
                              <img
                                src={socialmanagement}
                                alt="Your Imag"
                                class="static-image"
                              />
                            </div>
                          </div>
                          <div className="card-name">
                            <div className="card-headings">
                              <p>
                                {" "}
                                <span> Social Media </span> <span> Management </span>
                                
                                
                              </p>
                              <img src={cursorimage} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Digitalmarketing